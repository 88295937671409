//设置mac码
export const setMac = "/equipment/device/not_token/set_mac";
//根据mac获取设备信息
export const getMacInfo = "/equipment/device/not_token/get_info";
//检测上传图片
export const upimg = "/report/check/not_token/img";
//获取问诊问题
export const question = "/report/check/not_token/question";
//提交生成报告(无token)
export const setReport = "/report/check/not_token/submit";
//提交生成报告(有token)
export const setsetReportToken = "/report/check/submit";
//获取报告详情(无token)
export const getReport = "/report/check/not_token/get_report";
//获取报告详情（有token）
export const getReportToken = "/report/check/get_report";
//添加设备登录的uuid
export const popupDevice = "/not_token/bin/user-device";
//轮询获取token
export const userDevice = "/not_token/bin/user-device-info";
//发送报告的消息模版
export const sendTemplate = "/report/check/send_template";
//将用户和设备报告绑定
export const bindUserid = "/report/check/bind_user";
//根据token获取用户信息
export const userInfo = "/user-info";
//获取上一次检测的信息
export const lastInfo = "/report/check/last_info";
//根据服务类型获取相关信息
export const getServer = "/report/check/get_server";
//同上，无token
export const getServerNoToken = "/report/check/not_token/get_server";

/**
 * 开方报告
 */
//检测提交
export const submit = `/prescribe/submit`;
//根据父问题id获取他的所有子问题
export const question_add = `/prescribe/question/list`;
//同上，无token
export const question_add_notoken = `/prescribe/question/not_token/list`;
//获取报告详情
export const report_info = `/prescribe/info`;
//意见反馈
export const feedback = `/prescribe/feedback`;
//新增证候（报告无证候时调用）
export const add_sick = `/prescribe/add_sick`;
//查看问诊结果
export const report_node = `/prescribe/node`;
//获取设备模块列表
export const get_modules = `/report/check/get_modules`;
//添加用户
export const user_add = `/user`;
//获取账户医生和业务员状态
export const user_info = `/account-special-info`;
//上传名字和性别
export const be_doctor = `/account/apply-doctor`

//微信分享
export const config = `/wx/jsconfig`;
//上传到oss
export const osspolicy = `/report/oss`;
//客户列表
export const company_list = `/customer`;
//更新基础信息
export const user_change = `/user`;
//上传医生证书
export const add_zhengshu = `/doctor-material/account`;
//开方报告列表
export const prescribe_list  = `/prescribe/report/list`;
//统计，折线图饼状图
export const census_list  = `/report/healthtrend/get`;
//养生报告列表
export const report_list = `/report/list`;
//手机号或姓名登录
export const account_tel = `/not_token/create_account_by_tel`
//获取省市区
export const address = `/regions`;

//获取报告症候的趋势
export const get_sick_trend = `/report/healthtrend/get_sick_trend`;
//业务员红包统计列表(营销管理=>红包管理)
export const account = `/report/hongbao/account`;
//红包检测后需要关注的临时参数二维码
export const qr = `/report/hongbao/qr`;
//业务员创建红包
export const create = `/report/hongbao/create`;
//红包详情
export const hongbao_info = `/report/hongbao/info`;
//发送红包
export const hongbao_send = `/report/hongbao/send`;
//取消红包
export const hongbao_cancel = `/report/hongbao/cancel`;
//领取红包
export const hongbao_lingqu = `/report/hongbao/lingqu`;
//判断是否可以进行红包检查
export const can_report = `/report/hongbao/can_report`;
//红包业务端客户列表
export const custom_list = `/report/hongbao/custom_list`;
//红包业务端客户绑定
export const custom_bind = `/report/hongbao/custom_bind`;
//统计数据
export const tongji = `/report/hongbao/tongji`;
//红包领取记录
export const lingqu_list = `/report/hongbao/lingqu_list`;
//红包检测记录
export const test_list = `/report/hongbao/test_list`;
//身份证
export const identity = `http://127.0.0.1:9091/cbec/code/findIdCardData`;
// export const identity = `http://192.168.3.40:9091/cbec/code/findIdCardData`;
//北京 - 换token
export const gov_token = `/oauth/token`;
//北京 - 上传报告
export const gov_report = `/MPS/third/tcmci/upload`;