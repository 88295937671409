import axios from "@/utils/axios";
import { getReport , sendTemplate , getReportToken , userInfo , get_modules , gov_report } from "@/api";
import { Notify , Toast } from 'vant';
import { audioPaly , pageFun , ask , syndrome } from "@/utils";
import { reportover } from "@/utils/audio";
const echarts = require('echarts/lib/echarts');
//折线图
require('echarts/lib/chart/gauge');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');
//饼线图
require('echarts/lib/component/title');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/chart/pie');

let report = {
    namespaced: true,
    state: {
        info:false,            //报告数据存储        
        user:{},               //个人信息数据存储
        module:{               //模块数据存储   药茶养生等       
            renovate:true,
            data:[]
        },
        loading:false,         //报告加载中状态
        login:false,           //登录弹窗状态
        send:false,            //发送报告弹窗状态
        prescribe:false,       
        doctor:false,          //是否是医生
        page:0,                //页码   0是一整页显示 0以上是一页一题
        module_list:[],        //选择发送模块的数据存储
        module_name:[],        //用户发送模块的选择
        template_id:0,          
        history:false           //历史记录弹窗状态
    },
    mutations: {            //同步方法
        //清空数据
        closeFun(state){
            state.info = false;
            state.module = { renovate:true,data:[] }
            state.loading = false;
            state.login = false;
            state.send = false;
            state.page = 0;
            state.user = {};
            state.history = false;
        },
        //改变数值
        changeFun(state,data){
            state[data.key] = data.value;
        },
        //一级目录
        change(state,params){
            let module = state.module.data;
            for(let item in module[params.index].description_list){
                module[params.index].description_list[item].active = false;
            }
            module[params.index].description_list[params.index1].active = true;
            state.module = {
                renovate:!state.module.renovate,
                data:module
            }
        },
        //二级目录
        changeTwo(state,params){
            let module = state.module.data;
            for(let item in module[params.index].description_list[params.index1].list){
                module[params.index].description_list[params.index1].list[item].active = false;
            }
            module[params.index].description_list[params.index1].list[params.index2].active=true;
            state.module = {
                renovate:!state.module.renovate,
                data:module
            }
        },
        //翻页
        pageChange(state,data){
            let info = state.info;
            let _this = this;
            let w = document.body.clientWidth;
            let h = document.body.clientHeight;
            if(info.test_type==3){
                console.log(data.page);
                if(data.page==2){
                    state.page = 6;
                }else if(data.page==5){
                    state.page = 1;
                }else{
                    page1Fun(data.page);
                    //state.page = 1;
                }
            }else{
                if(data.page==3){
                    if(info.index_list){
                        setTimeout(function(){
                            if(w>h){
                                _this.dispatch('report/broken',{
                                    dom:'y-weight',
                                    date:info.index_list.date,
                                    data:info.index_list.bmi,
                                    max:40
                                });
                            }else{
                                _this.dispatch('report/broken',{
                                    dom:'weight',
                                    date:info.index_list.date,
                                    data:info.index_list.bmi,
                                    max:40
                                });
                            }
                        },1)
                    }
                }else if(data.page==1){
                    setTimeout(function(){
                        if(w>h){
                            _this.dispatch('report/meter','mainwidth');
                        }else{
                            _this.dispatch('report/meter','main');
                        }
                    },1)
                }
                
                page1Fun(data.page);
            }
            
            function page1Fun(page){
                if(!pageFun(page,info)){
                    page1Fun(data.add>0?page+1:page-1);
                }else{
                    console.log(page,'确定页数');
                    state.page = page;
                }
            }
        },
        //仪表盘
        Meter(state,params){
            var chartDom = document.getElementById(params);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option
            option = {
                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 100,
                    splitNumber: 0,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.5, '#F36440'],
                                [0.65, '#F89143'],
                                [0.8, '#ECDB44'],
                                [0.9, '#5CC7BC'],
                                [1, '#83D186']
                            ]
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 15,
                        distance: -60,
                        formatter: function (value) {
                            return value?value:''
                        }
                    },
                    detail: {
                        fontSize: 30,
                        offsetCenter: [0, '40%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return '';
                        },
                        color: 'auto'
                    },
                    data: [{
                        value: state.info.score,
                    }]
                }]
            };
            option && myChart.setOption(option);
        },

        Send(state,params){
            axios(`/api${sendTemplate}`,{
                ...params.data,
                module_ids:state.module_name,
                template_id:state.template_id
            },'POST')
			.then((data)=>{
                if(data.code==1){
                    //语音
                    audioPaly.play(reportover);
                    state.send = false;
                    Toast.success('发送成功！');
                }else{
                    Notify(data.msg);
                }
            },function(error) {
                if(error.code==401){
                    Notify(error.msg);
                    state.login = true;
                }else{
                    Notify(error.msg);
                }
            });
        },

        //折线图
        Broken(state,params){
            var chartDom = document.getElementById(params.dom);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                xAxis: {
                  type: 'category',
                  data: params.date,
                  axisTick:{
                    alignWithLabel:true
                  }
                },
                yAxis: {
                  type: 'value',
                  max:params.max
                },
                series: [
                  {
                    data: params.data,
                    type: 'line',
                    symbolSize: 10,
                  }
                ]
            };
      
            option && myChart.setOption(option);
        },

        //饼状图
        Cake(state,params){
            var chartDom = document.getElementById(params.dom);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color:[
                    '#F16B86',
                    '#F99938',
                    '#FABF38',
                    '#68CBEE',
                    '#6BD890'
                ],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '50%',
                        data: params.data,
                        emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                        }
                    }
                ]
            };
      
            option && myChart.setOption(option);
        },
        
        //请求
        User(state,params){
            axios(`/userapi${userInfo}`,{
                ...params,
                event:1
            }).then((data)=>{
                if(data.code==1){
                    state.user = {
                        nikeName:data.data.nikeName,
                        name:data.data.name,
                        url:data.data.avatarUrl
                    }
                }
            });
        },

        //发送请求
        Axios(state,params){
            state.loading=true;
            let report_api = sessionStorage.token?getReportToken:getReport;
            axios(`/api${report_api}`,params)
			.then((data)=>{
                state.loading=false;
                if(data.code==1){
                    //洗数据
                    let big = [] , little = [] ,bigData = [],littleData=[];
                    for( let item in data.data.syndrome_danger ){
                        let hot = 0 , syndrome_danger = data.data.syndrome_danger;
                        for(let i=0,n=syndrome_danger[item].list.length;i<n;i++){
                            if(syndrome_danger[item].list[i].risk_index){
                                hot++;
                                little.push(syndrome_danger[item].list[i].disease_name);
                                data.data.syndrome_danger[item].list[i].key = syndrome_danger[item].name;
                                littleData.push(syndrome_danger[item].list[i]);
                            }
                        }
                        if(hot>0){
                            big.push(syndrome_danger[item].name);
                            bigData.push(syndrome_danger[item]);
                        }
                    }
                    
                    if(data.data.index_list){
                        let bmi=[],date=[],score=[];
                        data.data.index_list.score.map((scores,index)=>{
                            if(scores!==0){
                                let sliceDate = data.data.index_list.date[index].split('-');
                                bmi.push(data.data.index_list.bmi[index]);
                                date.push(`${sliceDate[1]}-${sliceDate[2]}`);
                                score.push(data.data.index_list.score[index]);
                            }
                        })
                        data.data.index_list.bmi = bmi.reverse();
                        data.data.index_list.date = date.reverse();
                        data.data.index_list.score = score.reverse();
                    }

                    data.data.bigData = bigData;
                    data.data.big = big;
                    data.data.little = little;
                    data.data.littleData = littleData;

                    let module_list = data.data.module_list;

                    //模块
                    if(module_list){
                        for(let i=0,n=module_list.length;i<n;i++){
                            let description_list = data.data.module_list[i].description_list;
                            let hoo = 0;
                            for(let description in description_list){
                                if(hoo == 0){
                                    module_list[i].description_list[description].active = true;
                                }
                                if(module_list[i].description_list[description].list){
                                    module_list[i].description_list[description].list[0].active = true;
                                }   
                                hoo++;
                            }
                        }
                    }

                    if(data.data.tizhi_content){
                        data.data.tizhi_content = JSON.parse(data.data.tizhi_content);
                        console.log(data.data.tizhi_content);
                    }

                    

                    if(data.data.report_template_type==2){
                        data.data.sick_text_array = data.data.viscera.syndrome_name2.split(',');
                        data.data.viscera.syndrome_desc = data.data.viscera.syndrome_desc2;
                    }

                    console.log(module_list);
                    
                    state.info = data.data;
                    state.module = {
                        renovate:true,
                        data:module_list?module_list:[]
                    }

                    let flow = sessionStorage.getItem('flow');
                    if(flow && JSON.parse(flow).flow_info.report_style==2){
                        state.page = 1;
                    }else{
                        state.page = 0;
                    }
                    
                    let _this = this;
                    let w = document.body.clientWidth;
                    let h = document.body.clientHeight;
                    if(data.data.test_type!==3){
                        if(state.page==0){
                            //仪表盘
                            setTimeout(function(){
                                if(w>h){
                                    _this.dispatch('report/meter','mainwidth');
                                }else{
                                    _this.dispatch('report/meter','main');
                                }
                                if(data.data.index_list){
                                    let w = document.body.clientWidth;
                                    let h = document.body.clientHeight;
                                    if(w>h){
                                        _this.dispatch('report/broken',{
                                            dom:'y-weight',
                                            date:data.data.index_list.date,
                                            data:data.data.index_list.bmi,
                                            max:40
                                        });
                                    }else{
                                        _this.dispatch('report/broken',{
                                            dom:'weight',
                                            date:data.data.index_list.date,
                                            data:data.data.index_list.bmi,
                                            max:40
                                        });
                                    }
                                    
                                }
                            },1);
                        }else if(state.page==1){
                            setTimeout(function(){
                                if(w>h){
                                    _this.dispatch('report/meter','mainwidth');
                                }else{
                                    _this.dispatch('report/meter','main');
                                }
                            },1);
                        }
                    }

                    if(sessionStorage.token){
                        let obj = {};
                        if(data.data.user_id){
                            obj.id = data.data.user_id;
                        }
                        _this.dispatch('report/axiosUser',obj);
                    }

                    let testing_type = JSON.parse(flow).testing_type;
                    if(testing_type==5){        //立创
                        let callback_result_text = JSON.parse(data.data.callback_result_text);
                        if(sessionStorage.info){
                            let user_info = JSON.parse(sessionStorage.info);
                            let random = {
                                TcmNo:`${user_info.random}`,
                                personInfo:{
                                    IdCard:data.data.idc,
                                    Name:data.data.user_name,
                                    Sex:data.data.sex==1?'男':(data.data.sex==2?'女':'未知'),
                                    Birthday:data.data.birthday
                                },
                                createTime:data.data.created_at,
                                tcmResult:{
                                    PCaList:callback_result_text.PCaList,
                                    QCaList:callback_result_text.QCaList
                                },
                            }

                            syndrome.map((item)=>{
                                callback_result_text.CasList.map((item1)=>{
                                    if(item.name == item1.CaName){
                                        random[item.key] = `${item1.CaScore}`;
                                    }
                                })
                            });

                            ask.map((item2)=>{
                                callback_result_text.QaList.map((item3)=>{
                                    if(item2.id == item3.Sn){
                                        random[item2.key] = `${item3.A}`;
                                    }
                                })
                            })

                            console.log(JSON.stringify(random));
                            let lc_json = {
                                random:user_info.random,
                                data:JSON.stringify(random)
                            }
                            window.ipcRenderer.send("fileJson",lc_json);
                        }
                    }

                    console.log(JSON.parse(data.data.callback_result_text),1);

                    if(testing_type==6 && sessionStorage.govReport){        //北京
                        let callback_result_text = JSON.parse(data.data.callback_result_text);
                        let equipment = JSON.parse(sessionStorage.equipment);
                        let random = {
                            idCard:data.data.idc?data.data.idc:'',
                            name:data.data.user_name?data.data.user_name:'',
                            mac:equipment.mac,
                            mainConstitution:callback_result_text.PCaList,
                        }

                        let casList = [];
                        callback_result_text.CasList.map((list)=>{
                            casList.push({
                                constitutionName:list.CaName,
                                constitutionScore:list.CaScore,
                                constitutionResult:list.CaDr
                            })
                        });
                        random.scoreAndResults = casList;

                        let qaList = [];
                        callback_result_text.QaList.map((list1)=>{
                            qaList.push({
                                sn:list1.Sn,
                                question:list1.Q,
                                answer:list1.A
                            })
                        });
                        qaList = qaList.sort((a, b) => a.sn - b.sn);
                        random.answers = qaList;
                        
                        console.log(random);
                        _this.dispatch('report/govReport',{
                            token:sessionStorage.govReport,
                            data:random
                        });
                    }
                }else{
                    Toast.fail(data.msg);
                }
            });
        },
        //发送模块
        SendMin(state,params){
            axios(`/api${get_modules}`,params)
            .then((data)=>{
                state.template_id = data.data.template_id;
                state.module_list = data.data.list;
            });
        },
        //北京 - 上传报告
        GovReport(state,params){
            console.log(params);
            axios(`/govapi${gov_report}`,params.data,'POST',{
                Authorization:`Bearer ${params.token}`
            }).then((data)=>{
                console.log(data);
                sessionStorage.removeItem('govReport');
            });
        },
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('Axios',params)
        },
        axiosUser({commit},params){
            commit('User',params)
        },
        meter({commit},params){
            commit('Meter',params)
        },
        broken({commit},params){
            commit('Broken',params)
        },
        cake({commit},params){
            commit('Cake',params)
        },
        axiosSend({commit},params){
            commit('Send',params)
        },
        sendMain({commit},params){
            commit('SendMin',params)
        },
        govToken({commit},params){
            commit('GovToken',params)
        },
        govReport({commit},params){
            commit('GovReport',params)
        },
    },
    getters: {},
}



export default report;